import React from "react";
import roadmap from "./pictures/roadmap.jpeg";
import icon1 from "./pictures/Asset1.svg";
import icon2 from "./pictures/Asset2.svg";
import icon3 from "./pictures/Asset3.svg";
import icon4 from "./pictures/Asset4.svg";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bgContainer: {
    // maxWidth: "700px",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  header: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    // border: "2px solid green",
    alignItems: "center",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",

    gap: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
      // border: "2px solid blue",
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    // border: "2px solid blue",
    gap: "5px",
  },
  subsection: {
    display: "flex",
    alignItems: "flex-start",
  },
  imgs: {
    width: "20%",
    // order: 1
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  img: {
    border: "20px solid #f5fcff",
    borderRadius: theme.spacing(3),
    margin: "20px",

    [theme.breakpoints.up("xs")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "30%",
    },
  },
  topic: {
    fontWeight: "bold",
    marginBottom: 2,
  },
}));

export default function Roadmap() {
  const classes = useStyles();
  return (
    <Box className={classes.bgContainer}>
      <Box className={classes.header}>
        <Typography
          //   variant="h6"
          fontWeight="bold"
          sx={{
            fontSize: {
              xs: 25,
              sm: 40,
            },
          }}
        >
          Activity Roadmap
        </Typography>
        <Typography>
          Let's walk you through the process of the competition
        </Typography>
      </Box>

      <Box className={classes.container}>
        <Box className={classes.sectionContainer}>
          <Box className={classes.section}>
            <Typography className={classes.topic} fontWeight="bold">
              Register
            </Typography>
            <Box className={classes.subsection}>
              <Typography>
                Registration ensures your child's participation in the
                competition, officially making them a contestant for the Kiddies
                Crown Contest. Please ensure accurate completion of registration
                details.
              </Typography>
              <img src={icon1} alt="Register" className={classes.imgs} />
            </Box>
          </Box>

          <Box className={classes.section}>
            <Typography
              className={classes.topic}
              fontWeight="bold"
              textAlign="right"
            >
              Campaign
            </Typography>
            <Box className={classes.subsection}>
              <img src={icon2} alt="Register" className={classes.imgs} />
              <Typography textAlign="right">
                Once the contest begins, you'll receive a special campaign
                poster. This poster is designed to assist your child in seeking
                votes from friends and family during the campaign.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionContainer}>
          <Box className={classes.imgContainer}>
            <img src={roadmap} alt="child" className={classes.img} />
          </Box>
        </Box>

        <Box className={classes.sectionContainer}>
          <Box className={classes.section}>
            <Typography className={classes.topic} fontWeight="bold">
              Vote
            </Typography>
            <Box className={classes.subsection}>
              <Typography>
                This holds the key to your child's success in the contest.
                Campaign and gather votes actively to secure the spotlight for
                your child as the STAR of the competition.
              </Typography>
              <img src={icon3} alt="Register" className={classes.imgs} />
            </Box>
          </Box>

          <Box className={classes.section}>
            <Typography
              className={classes.topic}
              fontWeight="bold"
              textAlign="right"
            >
              Win
            </Typography>
            <Box className={classes.subsection}>
              <img src={icon4} alt="Register" className={classes.imgs} />
              <Typography textAlign="right">
                At the conclusion of the contest, the victorious individual will
                be publicly announced as Mr. or Miss Crown 2024, receiving a
                cash prize of N500,000.00, along with rewards valued at 300,000.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
