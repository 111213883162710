import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container } from "@mui/system";
import { Button } from "@mui/material";
import winner from "./pictures/grand-winner.jpeg";
import first from "./pictures/1st.jpeg";
import second from "./pictures/2nd.jpeg";
import third from "./pictures/3rd.jpeg";
import fourth from "./pictures/4th.jpeg";

const userData = [
  {
    name: "Godswill Asianya",
    main: "My name is Ugonnaya Godswill Asianya. I am 3years old. I was born on 1st May,was born in Lagos State.I came from a family of four of which i am the first Child of my parents. The name of my school is New Fountain Montessori Children school,I am in preschool 2. My Favourite Subject is numeracy. My best food is jollof rice while my favourite cereal is golden morn. My hobbies are colouring and watching cartoon. My dislike is dirtiness. My best colour is white. I am smart,vibrant and loveable Child. I enjoys playing outdoor games with my friends. I am a courageous and fearless child with the fear of God instilled in me.",
    picture: winner,
    position: "Grand Winner",
  },
  {
    name: "Melvin Nwora",
    main: "Kamnyechukwuotito Melvin Nwora Onuorah was born on the 8th of Sept 2020. He is  the best gift a mother can ask for. His energy is second to none. Melvin as a baby was very peaceful, you would not even know I had a new born, he made motherhood so seamless and hassle free. Growing up now, Melvin is a whole bundle of energy, he loves to do things on his own even as he is a toodler,  he loves to dance, anywhere he hears an interesting beat or music, you must see him go down low, he is an early riser, he attends St Lawrence's School, Awka Road, Onitsha, he loves to write in as much as it's ineligible for now, he also like to talk although it's incoherent and he loves calling everyone baby especially I his mum as if he's a big boy. He loves to sing too and  he is a very intelligent boy. He does things kids/toodler of his age hasn't even dreamt of doing. Melvin is just an amazing child and I'm proud to be his mum.",
    picture: first,
    position: "1st Runner-up",
  },
  {
    name: "Bella Tiaraoluwa",
    main: "She is from the family of Mr and Mrs Abayomi Balogun-Kuku From Ijebu Ode in Ogun State. She was born on the 11th of April,2022. Bella means more than a million to me, been my womb opener, daddy's girl and everybody's choice. She is an amazing daughter, sweetest baby, she is beautiful and wonderfully made by God, she is very young but always smiling, very active and lovely. I signed her up because she has what she takes to be a winner, though she might be very young but she is up to the task.",
    picture: second,
    position: "2nd Runner-up",
  },
  {
    name: "Purity Munachimso",
    main: "Purity Nsikak Munachimso Uwemedimo is her name, she hails from Ikot-Obio Ndoho in Mkpat-Enin Local Government, Akwa ibom state. She was born on 18th May, 2021 into the family of Mr&Mrs. Uwemedimo Essien Jeremiah. Purity is a child we prayed and asked from God, she is a perfect example of a graced and favoured child surrounded by great Aunties and Uncles who shower her with so much love and gifts. She brought so much joy, light and Love to us. Just as she brightens our lives, so shall her life continue to shine brighter and brighter wherever she finds herself. We love her so very much❤️",
    picture: third,
    position: "3rd Runner-up",
  },
  {
    name: "Princess Ojulari",
    main: "Princess Sunmisola Ojulari is the best thing that happened to the family of Ojulari. Princess was born into the world on May 8th 2021, she's my first child and biggest blessing. She possesses the perky and joyous behavior (just like her dad), amazing beautiful young lady.  I signed her up for the Kiddies Crown Contest because she's a born champion and deserves every beautiful things of life. I can't wait to see the beautiful things the  future holds for her.",
    picture: fourth,
    position: "4th Runner-up",
  },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: theme.spacing(5),
  },

  bold: {
    fontWeight: 700,
    display: "block",
    fontSize: 40,
  },
  btn: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function AboutPastChamps() {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h5" component="h4">
        {" "}
        Meet Our Previous <span className={classes.bold}>WINNERS</span>
      </Typography>
      <Typography>
        Enlisted below are the winners of our last edition of the Kiddies Crown
        Contest.
      </Typography>
      <Box sx={{ marginY: 4 }} className={classes.cards}>
        {userData.map((user, index) => (
          <Card sx={{ maxWidth: 345 }} key={index}>
            <CardMedia
              component="img"
              height="500"
              image={user.picture}
              alt="Paella dish"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            />
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                {/* <FavoriteIcon /> */}
                <Typography
                  sx={{ fontWeight: "900", ml: 1, textDecoration: "underline" }}
                >
                  {" "}
                  {user.position}: <span> {user.name.toUpperCase()}</span>{" "}
                </Typography>
              </IconButton>
              <ExpandMore
                expand={false}
                aria-expanded={true}
                aria-label="show more"
              >
                {/* <ExpandMoreIcon /> */}
              </ExpandMore>
            </CardActions>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>{user.main}</Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Box>
      <Box sx={{ m: 2, display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" href="/about">
          Go Back
        </Button>
      </Box>
    </Container>
  );
}
