import React from "react";
import { usePaystackPayment } from "react-paystack";
import Button from "@mui/material/Button";
const url = process.env.REACT_APP_PS_KEY;
// const url = "pk_test_5e3696a3645df2464029ea462a20dd5a2e7a2a22";

export default function PaystackAuth({
  handlePaymentSuccess,
  handlePaymentFail,
  vote,
  id,
  disabled,
}) {
  const config = {
    reference: new Date().getTime().toString(),
    email: `${id}-${Date.now().toString()}@gmail.com`,
    publicKey: url,
  };

  const onSuccess = (reference) => {
    console.log(reference);
    handlePaymentSuccess();
  };

  const onClose = () => {
    handlePaymentFail();
    console.log("payment failed");
  };

  const initializePayment = usePaystackPayment({
    ...config,
    amount: vote * 5000,
  });

  return (
    <Button
      variant="contained"
      disabled={disabled}
      sx={{ fontWeight: "bold" }}
      size="large"
      onClick={() => {
        initializePayment(onSuccess, onClose);
      }}
    >
      VOTE
    </Button>
  );
}
