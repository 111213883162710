import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Crown, Medal } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#f5fcff",
    // border: "1px solid red",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(23),
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(30),
      flexDirection: "row",
      alignItems: "normal",
      gap: theme.spacing(6),
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
  },
  winner: {
    // border: "1px solid blue",
    backgroundColor: "#ffffff",
    textAlign: "center",
    boxShadow: "0 5px 12px rgb(0 0 0 / 0.2)",
    borderBottom: "10px solid orange",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "90vw",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "30vw",
    },
  },
}));

export default function Prizes() {
  const classes = useStyles();

  return (
    <Box className={classes.bg}>
      <Box className={classes.winner}>
        {/* <EmojiEventsIcon color="success" fontSize="large" /> */}
        <Crown />
        <Typography variant="h5" fontWeight="bold">
          The Winners (1st-3rd) Gets:
        </Typography>
        <Typography>
          The Grand Winner will be Crowned “Mr or Miss Crown 2024”. Winners gets
          a cash prize of N3,500,000 ipad, family package, and other gifts. Lots
          of gifts and benefits.
        </Typography>
      </Box>
      <Box className={classes.winner}>
        <Medal />
        <Typography variant="h5" fontWeight="bold">
          Top 5 Contestants Gets:
        </Typography>
        <Typography>
          Our Top 5 Contestants won't just shine—they'll also take home
          fantastic consolation prizes!
        </Typography>
      </Box>
    </Box>
  );
}
