import { Box, Typography } from "@mui/material";
import CountDown from "../utilities/countdown";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { titleCase } from "./formatter";
import Loader from "../utilities/loader";
import { useParams } from "react-router-dom";
const url = process.env.REACT_APP_GET_USERNAME;
// const url = "http://127.0.0.1:9090/api/user/get-name";

export default function TempContestant() {
  const { id } = useParams();
  const { day, hour, min, sec } = CountDown("October 14, 2024");
  const [name, setName] = useState("");
  const [load, setLoad] = useState(true);

  useEffect(() => {
    axios
      .get(`${url}/${id}`)
      .then(({ data }) => {
        // console.log(data.name)
        setName(data.name);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      })
      .finally(() => {});
  }, []);

  return (
    <div>
      {load && <Loader />}
      {!load && (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Hello! 👋
          </Typography>
          <Typography>
            Welcome {name ? "to" : ""}{" "}
            <span style={{ fontWeight: "bold" }}>{titleCase(name)}</span>
            {name ? "'s profile" : ""}. <br />
            Activities for the{" "}
            <span style={{ fontWeight: "bold" }}>Semi Final</span> of the
            Kiddies Crown Contest starts Monday, 14th October, 2024.
          </Typography>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>Countdown:</Typography>
          <Typography variant="h5" fontWeight="bold">
            {day}d: {hour}h: {min}m: {sec}s
          </Typography>
        </Box>
      )}
    </div>
  );
}
