import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Countdown from "./countdown";

export default function ComingSoon({ src }) {
  // const { day, hour, min, sec } = Countdown("December 2, 2023");

  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 5,
      }}
    >
      <Typography fontWeight="bold" variant="h6" marginBottom={1}>
        Hello 👋
      </Typography>
      <Typography paragraph>
        During the next stage (stage 2), you'll be able to see{" "}
        {src === "contestants"
          ? "all other contestants"
          : "the real-time winners of the contest"}{" "}
        here.
      </Typography>
      {/* <Typography>
        Stage 2 starts in: <br />{" "}
        <strong>
          {day}d: {hour}h: {min}m: {sec}s
        </strong>
      </Typography> */}
    </Box>
  );
}
