import { useState } from "react";

export default function CountDown(endDate) {
  // const endDate =  "September 13, 2022";
  const [timer, setTimer] = useState({
    day: "00",
    hour: "00",
    min: "00",
    sec: "00",
  });

  const endDateTimeCode = new Date(endDate).getTime();
  const timeChecker = setInterval(() => {
    const now = new Date().getTime();
    const timeDiff = endDateTimeCode - now;

    const day = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
    const hour = Math.floor(
      (timeDiff % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    const min = Math.floor((timeDiff % (60 * 60 * 1000)) / (1000 * 60));
    const sec = Math.floor((timeDiff % (60 * 1000)) / 1000);

    if (timeDiff > 0) {
      setTimer({ day, hour, min, sec });
    } else {
      clearInterval(timeChecker);
    }
  }, 1000);

  return {
    day: timer.day,
    hour: timer.hour,
    min: timer.min,
    sec: timer.sec,
  };
}
