import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

export default function Accordion({ title, content }) {
  const [toggle, seToggle] = useState(false);

  function switchToggle() {
    seToggle(!toggle);
  }

  return (
    <Box margin={2} key={title}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={switchToggle}
      >
        <h4> {title} </h4>
        <Typography> {toggle ? "-" : "+"}</Typography>
      </Box>
      {toggle && <Typography marginTop={1}>{content}</Typography>}
    </Box>
  );
}
