import React, { useState } from "react";
import axios from "axios";
import { Field, Formik } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import StatusAlert from "./helpers/statusAlert";
import RegModal from "./helpers/RegModal";
import ValueProp from "./helpers/valueProp";
import { Camera } from "lucide-react";
const url = process.env.REACT_APP_REGISTER;
// const url = "http://127.0.0.1:9090/api/user/register";

export default function Register() {
  const [modal, setModal] = useState({
    open: false,
    profile: {
      id: "",
      name: "",
      gender: "",
    },
  });

  const [imagePreview, setImagepreview] = useState("");
  const [winner, setWinner] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [snapbar, setSnapbar] = useState({
    open: false,
    success: false,
  });

  const modalHandleClose = () => {
    setModal({ ...modal, open: false });
  };

  const snapbarHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnapbar({ ...snapbar, open: false });
  };

  const InputComponent = (props) => (
    <TextField
      fullWidth
      id="outlined-start-adornment"
      InputProps={{
        startAdornment: <InputAdornment position="start">+234</InputAdornment>,
      }}
      {...props}
    />
  );

  return (
    <Box sx={{ px: 2, mt: 10 }}>
      <ValueProp />
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        <Typography
          sx={{
            py: 3,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#E8F0FC",
            borderRadius: 1.5,
            mb: 2,
            fontWeight: "bold",
            color: "#666",
          }}
        >
          Registration Form
        </Typography>
        <Typography mb={2} variant="body2">
          Kindly fill out the following details correctly
        </Typography>
        <Formik
          initialValues={{
            name: "",
            age: "",
            gender: "",
            description: "",
            picture: null,
            parentName: "",
            phone: "",
            whatsapp: "",
          }}
          validate={(values) => {
            const errors = {};
            const validateErrors = {
              name: "Enter child's name",
              age: "Enter child's age",
              gender: "Select child's gender",
              picture: "No upload",
              phone: "Enter your phone number",
              whatsapp: "Enter your whatsapp number",
            };

            for (const key in validateErrors) {
              if (!values[key] && values[key] !== 0) {
                errors[key] = validateErrors[key];
              }
            }

            const regexValidate = {
              phone: "Invalid phone number",
              whatsapp: "Invalid whatsapp number",
            };

            for (const key in regexValidate) {
              if (
                !errors[key] &&
                !/^(?:0|(?<!\+234))[789]\d{9}$/.test(values[key])
              ) {
                errors[key] = regexValidate[key];
              }
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const formData = new FormData();
            formData.append(
              "user",
              JSON.stringify({
                name: values.name,
                age: values.age,
                gender: values.gender,
                description: values.description,
                parentName: values.parentName,
                phone: values.phone,
                whatsapp: values.whatsapp,
              })
            );

            formData.append("image", values.picture, "image");

            try {
              const { status, data } = await axios.post(url, formData);
              if (status === 200 || status === 201) {
                setSnapbar({ open: true, success: true });
                const { id, name, gender } = data.data;
                setModal({
                  ...modal,
                  open: true,
                  profile: { ...modal.profile, id, name, gender },
                });
              } else {
                setSnapbar({ open: true, success: false });
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ marginBottom: 3 }}>
                <TextField
                  fullWidth
                  name="name"
                  error={errors.name && touched.name ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  id="outlined-error-helper-text"
                  label="Child's Name"
                  helperText={errors.name}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                }}
              >
                <FormControl
                  sx={{ width: 400 }}
                  error={touched.age && errors.age ? true : false}
                >
                  <InputLabel id="demo-simple-select-error-label">
                    Age
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    name="age"
                    label="Age"
                    value={values.age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={0}>Less than 1 year</MenuItem>
                    <MenuItem value={1}>1 Year</MenuItem>
                    <MenuItem value={2}>2 Years</MenuItem>
                    <MenuItem value={3}>3 Years</MenuItem>
                    <MenuItem value={4}>4 Years</MenuItem>
                    <MenuItem value={5}>5 Years</MenuItem>
                    <MenuItem value={6}>6 Years</MenuItem>
                    <MenuItem value={7}>7 Years</MenuItem>
                    <MenuItem value={8}>8 Years</MenuItem>
                    <MenuItem value={9}>9 Years</MenuItem>
                    <MenuItem value={10}>10 Years</MenuItem>
                  </Select>
                  <FormHelperText>{errors.age}</FormHelperText>
                </FormControl>
                <FormControl
                  sx={{ width: 400 }}
                  error={touched.gender && errors.gender ? true : false}
                >
                  <InputLabel id="demo-simple-select-error-label">
                    Gender
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    name="gender"
                    label="Gender"
                    value={values.gender}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  <FormHelperText>{errors.gender}</FormHelperText>
                </FormControl>
              </Box>

              <Box marginTop={3}>
                <Box marginBottom={2} display="flex" alignItems="center">
                  <Checkbox
                    {...label}
                    size="small"
                    onChange={() => {
                      winner === true ? setWinner(false) : setWinner(true);
                    }}
                  />

                  <Typography variant="body1" lineHeight={1.2}>
                    Does{" "}
                    {values.name ? values.name?.toUpperCase() : "your child"}{" "}
                    deserve to win the Kiddies Crown Contest?
                  </Typography>
                </Box>
                {winner && (
                  <>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      id="outlined-error-helper-text"
                      label="Give reason(s)"
                      placeholder="e.g, Adeshola Chioma Aisha deserves to win because..."
                    />
                    <FormHelperText>
                      Note: This will be publicly displayed on{" "}
                      {values.name ? "" : "this contestant's"}
                      {values.name && values.name?.split(" ")[0] + "'s"} profile
                      page
                    </FormHelperText>
                  </>
                )}
              </Box>

              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography variant="span" marginTop={3} marginBottom={1}>
                  {" "}
                  Upload picture of Child
                </Typography>
                <Box
                  width="100%"
                  border={2}
                  borderRadius={2}
                  borderColor="rgba(0, 0, 0, 0.1)"
                  textAlign="center"
                  marginBottom={3}
                  padding={3}
                >
                  <Button
                    variant="outlined"
                    component="label"
                    endIcon={<Camera />}
                    size="small"
                    sx={{
                      ":hover": {
                        backgroundColor: "primary.light",
                        color: "#fff",
                      },
                      borderColor: touched.picture && errors.picture && "red",
                    }}
                  >
                    upload
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        values.picture = file;
                        const imagePath = URL.createObjectURL(file);
                        setImagepreview(imagePath);
                      }}
                    />
                  </Button>
                  {touched.picture && errors.picture && (
                    <FormHelperText error sx={{ textAlign: "center" }}>
                      {errors.picture}
                    </FormHelperText>
                  )}
                  {imagePreview && (
                    <Box marginTop={1}>
                      <img src={imagePreview} alt="" width="100" />
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
              <Box display="flex" flexDirection="column" gap={3}>
                <TextField
                  fullWidth
                  name="parentName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.parentName}
                  id="outlined-basic"
                  label="Parent Name"
                  variant="outlined"
                  sx={{
                    marginTop: 3,
                  }}
                />

                <Field
                  name="phone"
                  type="tel"
                  label="Phone Number"
                  onChange={handleChange}
                  helperText={errors.phone}
                  onBlur={handleBlur}
                  error={touched.phone && errors.phone ? true : false}
                  as={InputComponent}
                />

                <Field
                  name="whatsapp"
                  type="tel"
                  label="Whatsapp Number"
                  onChange={handleChange}
                  helperText={errors.whatsapp}
                  onBlur={handleBlur}
                  error={touched.whatsapp && errors.whatsapp ? true : false}
                  as={InputComponent}
                />
              </Box>
              <Button
                sx={{
                  my: 3,
                  fontWeight: "fontWeightBold",
                }}
                size="large"
                type="submit"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? "Registering..." : "Register"}
                {/* Registration closed */}
              </Button>
            </form>
          )}
        </Formik>
        <RegModal modal={modal} modalHandleClose={modalHandleClose} />
        <StatusAlert
          snapbar={snapbar}
          snapbarHandleClose={snapbarHandleClose}
        />
      </Container>
    </Box>
  );
}
