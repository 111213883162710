import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import gtb from "./icons/gtb.png";
import paystack from "./icons/paystack.png";
import gig from "./icons/gig.png";
import logo from "./icons/logo.svg";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { Facebook, Instagram } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  socials: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  icons: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
  },

  footer1: {
    backgroundColor: "#5C5D5F",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    color: "#fff",
    padding: theme.spacing(3),
  },
  footer2: {
    textAlign: "center",
    color: "#000",
    backgroundColor: "#fff",
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: theme.spacing(3),
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const display = pathname.match(/\/contestant\/\d{3}/) ? false : true;
  if (!display) {
    return null;
  }

  return (
    <Box sx={{ marginTop: 4 }}>
      <div className={classes.footer1}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <img src={logo} alt="logo" width="100" />
            <Typography>
              <strong>Kiddies Crown</strong> organizes events that’s geared
              towards an objective of putting smile on the faces of Nigerian
              families and extending our lending hands to the poor and homeless
              children at large.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              Our Partners
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 2,
              }}
            >
              <img src={gtb} alt="logo" width="50" />
              <img src={paystack} alt="logo" width="100" />
              <img src={gig} alt="logo" width="80" />
            </Box>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer2}>
        <Typography>
          Copyright © {new Date().getFullYear()} Kiddies Crown All Rights
          Reserved.
        </Typography>
        <div className={classes.socials}>
          <a href="https://www.instagram.com/kiddiescrown123/">
            <Instagram />
          </a>
          <a href="https://web.facebook.com/kiddiescrown123">
            <Facebook />
          </a>
        </div>
      </div>
    </Box>
  );
}
