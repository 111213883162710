import React, { useState } from "react";
import axios from "axios";
import PaystackAuth from "./paystackAuth";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@mui/styles";
import VoterDialog from "../voterDialog";
import { titleCase } from "../../helpers/formatter";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { MoveLeft } from "lucide-react";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const vote_update_url = process.env.REACT_APP_API_UPDATE_VOTE;
const log_update_url = process.env.REACT_APP_API_LOG_UPDATE;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  main: {
    fontWeight: "900",
    fontSize: "25px",
    color: "#333333",
  },
  subtitle: {
    color: "gray",
    fontWeight: "900",
  },
}));

export default function Payment() {
  const location = useLocation();
  const { id, name, currentVote, vote } = location.state;
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [paymentSuccessSnapbar, setPaymentSuccessSnapbar] = useState(false);
  const [infoSnapbar, setInfoSnapbar] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [time, setTime] = useState("");

  const handlePaymentSuccess = () => {
    setSuccess(true);
    setPaymentSuccessSnapbar(true);

    axios
      .post(vote_update_url, {
        id,
        time: Date.now().toString(),
        vote,
        oldCount: currentVote,
        newCount: currentVote + vote,
        voter: "Anonymous",
        byAdmin: false,
      })
      .then(function ({ data }) {
        const { log } = data;
        setTime(log[0].time);
        setDialog(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handlePaymentFail = () => {
    setPaymentSuccessSnapbar(true);
    setTimeout(() => {
      window.location.replace(`/contestant/${id}`);
    }, 4000);
  };

  const updateVoter = (voter) => {
    setDialog(false);
    setDisabled(true);

    if (voter) {
      axios
        .post(log_update_url, { id, time, voter })
        .then(function (response) {
          console.log("voter's name updated");
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    setPaymentSuccessSnapbar(false);
    setInfoSnapbar(true);
    setTimeout(() => {
      window.location.replace(`/contestant/${id}`);
    }, 6000);
  };

  const closePaymentSuccess = (event, reason) => {
    if (reason === "clickaway") return;
    setPaymentSuccessSnapbar(false);
  };

  const closeInfoSnapbar = () => {
    setInfoSnapbar(false);
  };

  return (
    <Container>
      <Box>
        <Typography
          textAlign="center"
          sx={{ fontWeight: "bold", marginTop: 10 }}
        >
          {" "}
          Voting Summary
        </Typography>
        <Box
          sx={{
            marginTop: 2,
            padding: 2,
            borderRadius: 1,
            border: "2px solid #F2F2F2",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div>
            <Typography variant="h5" className={classes.main}>
              {titleCase(name)}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Contestant's Name
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {id}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Contestant's ID
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {currentVote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Contestant's current vote count
            </Typography>
          </div>
          <Divider />
          <div>
            <Typography variant="h5" className={classes.main}>
              {vote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Number of votes selected
            </Typography>
          </div>
          <div>
            <Typography variant="h5" className={classes.main}>
              {currentVote + vote}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Votes count after casting this vote
            </Typography>
          </div>
          <PaystackAuth
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentFail={handlePaymentFail}
            disabled={disabled}
            vote={vote}
            id={id}
          />
        </Box>
        <Box sx={{ margin: "0 auto", my: 2 }}>
          <Button
            href={`/contestant/${id}`}
            sx={{ fontWeight: "bold" }}
            startIcon={<MoveLeft />}
          >
            Go Back
          </Button>
        </Box>
      </Box>
      <Snackbar open={paymentSuccessSnapbar} onClose={closePaymentSuccess}>
        <Alert
          onClose={closePaymentSuccess}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {success
            ? `The vote was a success - ${vote} has been counted for this contestant`
            : "The vote attempt did not succeed. Please give it another try. This page will be refreshed."}
        </Alert>
      </Snackbar>

      <Snackbar open={infoSnapbar} onClose={closeInfoSnapbar}>
        <Alert severity="info" sx={{ width: "100%" }}>
          <AlertTitle>Info</AlertTitle>
          Thank you! The votes for this contestant have been successfully
          updated. This page will now be refreshed to display the revised vote
          count.
        </Alert>
      </Snackbar>
      <VoterDialog open={dialog} updateVoter={updateVoter} />
    </Container>
  );
}
