import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import NavBar from "./components/NavBar";
import About from "./components/About";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import Register from "./components/Register";
import AboutKiddies from "./components/about/about-kiddies";
import AboutPastChamps from "./components/about/about-pastchamps";
import FeedAChild from "./components/about/about-feedAChild";
import Contestant from "./components/contestant/index";
import Payment from "./components/contestant/payment/payment";
import BankInfo from "./components/contestant/payment/bankInfo";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Terms from "./components/T&C";
import Dashboard from "./components/admin/Dashboard";
import UpdatePhoto from "./components/admin/updatePhoto";
import ComingSoon from "./components/utilities/comingSoon";
import LoginForm from "./components/admin/loginForm";
import Authentication from "./components/admin/protectedRoute";
// import Contestants from "./components/Contestants";
import TempContestant from "./components/helpers/tempContestant";
// import Champs from "./components/Champs";

export default function Router() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contestant/:id" element={<TempContestant />} />
        <Route
          exact
          path="/contestants"
          element={<ComingSoon src="contestants" />}
        />
        {/* <Route exact path="/elite-kids" element={<Champs />} /> */}
        <Route exact path="/about-kiddies" element={<AboutKiddies />} />
        <Route exact path="/previous-winners" element={<AboutPastChamps />} />
        <Route exact path="/feed-a-child" element={<FeedAChild />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/secured-payment" element={<Payment />} />
        <Route exact path="/bank-info" element={<BankInfo />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route
          path="/dashboard"
          element={
            <Authentication>
              <Dashboard />
            </Authentication>
          }
        />
        <Route exact path="/update-photo" element={<UpdatePhoto />} />
        {/* <Route exact path="/add-vote" element={<Dashboard />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
