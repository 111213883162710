import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import logo from "./icons/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import { Slide, useScrollTrigger } from "@mui/material";
import { Menu } from "lucide-react";

const drawerWidth = Math.ceil(window.screen.width / 4) * 3;

// const secondaryNavItems = ["home", "about", "register"];

const primaNavItems = [
  // "contestants",
  // "elite-kids",
];

const leftMenuItms = [
  "home",
  "contestants",
  // "elite-kids",
  "about-kiddies",
  "previous-winners",
  "feed-a-child",
  "privacy",
  "terms",
  "register",
];

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NavBar(props) {
  const { pathname } = useLocation();
  // console.log(pathname)

  const refinedPathname = pathname.replaceAll("/", "");
  const userOptions =
    primaNavItems.includes(refinedPathname) ||
    pathname.match(/\/contestant\/\d{3}/)
      ? true
      : false;

  const leftMenuOpt = userOptions
    ? leftMenuItms
    : [...leftMenuItms, ...primaNavItems];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ my: 2 }}>
        <img src={logo} alt="logo" width="100" />
      </Box>
      <Divider />
      <List sx={{ ml: 5 }}>
        {leftMenuOpt.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }} href={`/${item}`}>
              <ListItemText primary={item.toUpperCase()} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", mb: 7 }}>
      <HideOnScroll {...props}>
        <AppBar component="nav" sx={{ backgroundColor: "#000428" }}>
          <Toolbar>
            <button
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Menu />
            </button>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <img src={logo} alt="logo" width="100" />
            </Box>
            {userOptions && (
              <Box>
                {primaNavItems.map((item, index) => (
                  <NavLink
                    sx={{ color: "red" }}
                    to={`/${item}`}
                    key={index}
                    style={({ isActive }) => {
                      const defaultStyle = {
                        color: "#fff",
                        margin: 5,
                        padding: 5,
                        textDecoration: "none",
                      };
                      return isActive
                        ? { backgroundColor: "#0288d1", ...defaultStyle }
                        : defaultStyle;
                    }}
                  >
                    {item.toUpperCase()}
                  </NavLink>
                ))}
                <Box
                  sx={{
                    display: "inline",
                    position: "static",
                    right: 2,
                  }}
                ></Box>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};
