import { Box, Button, Typography } from "@mui/material";
import { MoveRight } from "lucide-react";
import React from "react";

export default function Action() {
  return (
    <Box
      sx={{
        backgroundColor: "#2690b1",
        padding: 2,
        color: "white",
        textAlign: "center",
      }}
    >
      <Box>
        <Typography fontWeight="bold" gutterBottom>
          HOW TO ENROLL <br />{" "}
          <span style={{ fontSize: "2rem" }}>
            Your Child in Kiddies Crown Contest?
          </span>
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ marginBottom: 2 }}>
          To get started, click the register button below and fill out all the
          requested information correctly.
        </Typography>
        <Button
          sx={{ color: "white", fontWeight: "bold" }}
          endIcon={<MoveRight />}
          href="/register"
        >
          Get started
        </Button>
      </Box>
    </Box>
  );
}
