import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import whatsapp from "./image/whatsapp.png";
import paymentSample from "./image/payment-sample.jpeg";
import { titleCase } from "../../helpers/formatter";
import { useLocation } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  italics: {
    fontStyle: "italics",
    fontWeight: "light",
  },
}));

export default function BankInfo() {
  const location = useLocation();
  const { id, name, vote } = location.state;
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 10 }}
      >
        <Typography>
          To vote for <span className={classes.bold}>{titleCase(name)}</span>{" "}
          through bank transfer/deposit, kindly make use of the bank information
          indicated below:
        </Typography>
        <Typography>
          Account Name: <strong>Kiddies Crown</strong>
          <br />
          Account No: <strong>0669795144</strong>
          <br />
          Bank Name: <strong>Guaranty Trust Bank (GTB)</strong>
          <br />
          Amount:{" "}
          <strong>
            NGN {vote * 50} ({vote} votes selected){" "}
          </strong>
        </Typography>
        <Typography>
          After making the deposit, please send the following information (see
          below) to our WhatsApp account for a prompt response and vote update.
          You can do this by tapping on the WhatsApp icon located at the
          bottom-right corner of your screen. We appreciate your cooperation and
          look forward to assisting you.
        </Typography>
        <ol style={{ margin: 0 }}>
          <li>Payment receipt</li>
          <li>Amount</li>
          <li>Contestant's name</li>
          <li>
            Contestant's ID.{" "}
            <strong>
              ({titleCase(name)}'s id is {id})
            </strong>
          </li>
          <li>Voter's name (Your name)</li>
        </ol>
        <Typography>
          <strong>For example:</strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={paymentSample} alt="demostration" width="300" />
        </Box>
        <Typography>
          Upon receiving payment confirmation from us, the corresponding vote(s)
          will be promptly updated. You will be notified of the update via a
          WhatsApp response.
        </Typography>
        <Typography gutterBottom>
          Please note that due to the high volume of receipt confirmation
          requests we receive, there may be a delay in our response. However,
          please be assured that your vote(s) will be processed and all payments
          will be accounted for.
        </Typography>
        <Typography>
          Thank you for your understanding and cooperation.
        </Typography>
        <Button
          href={`contestant/${id}`}
          // startIcon={<ArrowBackIcon />}
          sx={{ fontWeight: "bold" }}
        >
          back to contestant page
        </Button>
        <Box sx={{ position: "fixed", bottom: 10, right: 10 }}>
          <IconButton
            size="large"
            aria-label="whatsapp"
            href="https://wa.me/message/OVLKLOSR3AP7F1"
          >
            <img src={whatsapp} width="45" alt="whatsapp" />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
